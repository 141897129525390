import { ThemeProvider } from '@mui/material/styles';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import i18n from './config/i18n';
import './plugins';
import AppRouter from './routes/AppRouter';
import configureStore from './store/config/configureStore';

import theme from './theme/amnesty';
import './theme/amnesty.css';

const store = configureStore();
store.subscribe(() => {
  // eslint-disable-next-line no-console
});

// Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN });

const root = createRoot(document.getElementById("root"));
root.render(
  
    <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <ThemeProvider theme={theme}>
        <AppRouter />
      </ThemeProvider>
    </I18nextProvider>
  </Provider>
  
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
