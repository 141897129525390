import MuiAlert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { api } from 'config/config';
import { Form, Formik, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { login, snackbarError, snackbarSuccess } from 'store/actions';
import * as Yup from 'yup';

const PREFIX = 'ai-login';
const classes = {
    form: `${PREFIX}-form`,
    wrapper: `${PREFIX}-wrapper`,
};

const Root = styled('div')(({ theme }) => ({
    [`& .${classes.form}`]: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    [`& .${classes.wrapper}`]: {
        margin: theme.spacing(1),
        position: 'relative',
    },
}));

const PasswordForgottenSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
});

function Login({
    isAuthenticated,
    login,
    loading,
    values,
    handleChange,
    touched,
    errors,
    handleSubmit,
    snackbarSuccess,
    snackbarError,
    error,
}) {
    const [verified, setVerified] = useState(false);
    const { t } = useTranslation();
    const [dialogOpen, setDialogOpen] = useState(false);

    const openPasswordForgotten = () => {
        setDialogOpen(true);
    };

    const closePasswordForgotten = () => {
        setDialogOpen(false);
    };

    return (
        <Root>
            <Typography component="p" variant="overline" sx={{ textAlign: 'center' }}>
                {t('App title')}
            </Typography>
            <Typography component="h1" variant="h5" sx={{ textAlign: 'center' }}>
                {t('Login')}
            </Typography>

            {error ? (
                <MuiAlert
                    sx={{
                        marginTop: 1,
                        marginBottom: 0,
                        paddingY: 1,
                        paddingX: 2,
                    }}
                    severity="error"
                >
                    {t(`error.${error.error}`)}
                </MuiAlert>
            ) : (
                ''
            )}
            <form onSubmit={handleSubmit} noValidate className={classes.form}>
                <TextField
                    helperText={touched.email ? t(errors.email) : ''}
                    error={touched.email && Boolean(errors.email)}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label={t('Email')}
                    name="email"
                    autoComplete="email"
                    value={values.email}
                    onChange={handleChange}
                    autoFocus
                />
                <TextField
                    helperText={touched.password ? t(errors.password) : ''}
                    error={touched.password && Boolean(errors.password)}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label={t('Password')}
                    type="password"
                    id="password"
                    value={values.password}
                    onChange={handleChange}
                    autoComplete="current-password"
                />
                {/*
                <FormControlLabel
                    control={<Checkbox value="remember" color="primary" />}
                    label={t('Remember login')}
                />
                */}

                <GoogleReCaptchaProvider
                    reCaptchaKey="6LfectMUAAAAAIAWfoHlxKry4OiPS7qCyD_CQDrw"
                    language="de"
                >
                    <GoogleReCaptcha onVerify={(token) => setVerified(true)} />
                </GoogleReCaptchaProvider>
                <div className={classes.wrapper}>
                    <Button
                        fullWidth
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="large"
                        sx={{
                            margin: (theme) => theme.spacing(3, 0, 3),
                        }}
                        disabled={loading || !verified}
                    >
                        {t('Login')}
                    </Button>
                    {loading && (
                        <CircularProgress
                            size={24}
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: -12,
                                marginLeft: -12,
                            }}
                        />
                    )}
                </div>
                <Grid container>
                    <Grid item xs />
                    <Grid item>
                        <Link onClick={openPasswordForgotten} variant="body2">
                            {t('Forgot password')}?
                        </Link>
                    </Grid>
                </Grid>
            </form>
            <Dialog
                open={dialogOpen}
                onClose={closePasswordForgotten}
                aria-labelledby="form-dialog-title"
            >
                <Formik
                    initialValues={{
                        email: '',
                    }}
                    validationSchema={PasswordForgottenSchema}
                    onSubmit={async (values) => {
                        try {
                            await axios.request({
                                method: 'post',
                                url: api.path('auth.passwordCreate'),
                                data: values,
                            });
                            snackbarSuccess(t('Email sent'));
                            closePasswordForgotten();
                        } catch (e) {
                            snackbarError(t('Error occured'));
                        }
                    }}
                >
                    {({ errors, touched, handleChange }) => (
                        <Form>
                            <DialogTitle id="form-dialog-title">{t('Forgot password')}</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    {t('Enter the email address connected to your account ...')}
                                </DialogContentText>
                                <TextField
                                    helperText={touched.email ? t(errors.email) : ''}
                                    error={touched.email && Boolean(errors.email)}
                                    autoFocus
                                    margin="dense"
                                    name="email"
                                    variant="outlined"
                                    label={t('Email address')}
                                    type="text"
                                    fullWidth
                                    onChange={handleChange}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={closePasswordForgotten} color="primary">
                                    {t('Cancel')}
                                </Button>
                                <Button type="submit" color="primary">
                                    {t('Send link')}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </Root>
    );
}

const MyEnhancedLogin = withFormik({
    // enableReinitialize: true,
    mapPropsToValues: ({ login }) => ({
        email: '',
        password: '',
        login,
    }),

    validationSchema: Yup.object().shape({
        email: Yup.string().email('Email invalid').required('Required'),
        password: Yup.string().required('Required'),
    }),

    handleSubmit: (values, { setSubmitting }) => {
        values.login(values.email, values.password);
    },
})(Login);

Login.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    login: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.token !== null,
    loading: state.auth.loading,
    error: state.auth.error,
});

const mapDispatchToProps = (dispatch) => {
    return {
        snackbarSuccess: (message) => {
            dispatch(snackbarSuccess(message));
        },
        snackbarError: (message) => {
            dispatch(snackbarError(message));
        },
        login: (email, password) => {
            dispatch(login(email, password));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyEnhancedLogin);
