import MuiAlert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { api } from 'config/config';
import i18n from 'config/i18n';
import { withFormik } from 'formik';
import withRouter from 'helpers/withRouter';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { snackbarError, snackbarSuccess } from 'store/actions';
import * as Yup from 'yup';

const PREFIX = 'ai-pw-reset';
const classes = {
    form: `${PREFIX}-form`,
    wrapper: `${PREFIX}-wrapper`,
};

const Root = styled('div')(({ theme }) => ({
    [`& .${classes.form}`]: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    [`& .${classes.wrapper}`]: {
        margin: theme.spacing(1),
        position: 'relative',
    },
}));

function PasswordReset({
    isAuthenticated,
    login,
    values,
    handleChange,
    touched,
    errors,
    handleSubmit,
    setFieldValue,
    snackbarSuccess,
    snackbarError,
    error,
    router,
}) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    console.log(errors);

    useEffect(() => {
        fetchTokenInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchTokenInfo = async () => {
        console.log('fetchTokenInfo');
        try {
            setLoading(true);
            const { data: response } = await axios.request({
                method: 'get',
                url: api.path('auth.passwordTokenFind', { token: router.params.token }),
            });
            // setReset(response);
            console.log(response);
            setFieldValue('email', response.email);
            setFieldValue('token', response.token);
            setLoading(false);
        } catch (e) {
            console.log(e);
            setLoading(false);
        }
    };

    return (
        <Root>
            <Typography component="p" variant="overline" sx={{ textAlign: 'center' }}>
                {t('App title')}
            </Typography>
            <Typography component="h1" variant="h5" sx={{ textAlign: 'center' }}>
                {t('Reset password')}
            </Typography>

            {false && error ? (
                <MuiAlert
                    severity="error"
                    sx={{
                        marginTop: 1,
                        marginBottom: 0,
                        padding: (theme) => theme.spacing(1, 2),
                    }}
                >
                    {t(`error.${error.error}`)}
                </MuiAlert>
            ) : (
                ''
            )}
            <form onSubmit={handleSubmit} noValidate className={classes.form}>
                <TextField
                    helperText={touched.email ? t(errors.email) : ''}
                    error={touched.email && Boolean(errors.email)}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label={t('Email')}
                    name="email"
                    autoComplete="email"
                    value={values.email}
                    onChange={handleChange}
                />
                <TextField
                    helperText={touched.password ? t(errors.password) : ''}
                    error={touched.password && Boolean(errors.password)}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label={t('Password')}
                    type="password"
                    id="password"
                    value={values.password}
                    onChange={handleChange}
                />
                <TextField
                    helperText={
                        touched.password_confirmation ? t(errors.password_confirmation) : ''
                    }
                    error={touched.password_confirmation && Boolean(errors.password_confirmation)}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password_confirmation"
                    label={t('Password confirmation')}
                    type="password"
                    id="password_confirmation"
                    value={values.password_confirmation}
                    onChange={handleChange}
                />
                <div className={classes.wrapper}>
                    <Button
                        fullWidth
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="large"
                        sx={{
                            margin: (theme) => theme.spacing(3, 0, 3),
                        }}
                        disabled={loading}
                    >
                        {t('Login')}
                    </Button>
                    {loading && (
                        <CircularProgress
                            size={24}
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: -12,
                                marginLeft: -12,
                            }}
                        />
                    )}
                </div>
                <Grid container>
                    <Grid item xs />
                    <Grid item>
                        <Link href="/login" variant="body2">
                            {t('Go to Login')}
                        </Link>
                    </Grid>
                </Grid>
            </form>
        </Root>
    );
}

const MyEnhancedForm = withFormik({
    enableReinitialize: true,
    mapPropsToValues: () => ({
        email: '',
        password: '',
        password_confirmation: '',
        token: '',
    }),
    validationSchema: Yup.object().shape({
        token: Yup.string().required('Required'),
        email: Yup.string().email('Email invalid').required('Required'),
        password: Yup.string().required('Password is required').min(6, 'Too short'),
        password_confirmation: Yup.string().oneOf(
            [Yup.ref('password'), null],
            'Passwords must match'
        ),
    }),

    handleSubmit: async (values, { setSubmitting, props, setFieldValue }) => {
        console.log("you're here");
        try {
            const { data: response } = await axios.request({
                method: 'post',
                url: api.path('auth.passwordReset'),
                data: values,
            });

            if (response.id) {
                props.snackbarSuccess(i18n.t('Password reset successfully'));
                props.router.navigate('/login');
            } else {
                props.snackbarError(i18n.t('Password reset error'));
            }
        } catch (e) {
            console.log(e);
            props.snackbarError(i18n.t('Error occured'));
        }
    },
})(PasswordReset);

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.token !== null,
    loading: state.auth.loading,
    error: state.auth.error,
});

const mapDispatchToProps = (dispatch) => {
    return {
        snackbarSuccess: (message) => {
            dispatch(snackbarSuccess(message));
        },
        snackbarError: (message) => {
            dispatch(snackbarError(message));
        },
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyEnhancedForm));
