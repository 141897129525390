import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AnalyticsRoundedIcon from '@mui/icons-material/AnalyticsRounded';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import BarChartIcon from '@mui/icons-material/BarChart';
import DashboardIcon from '@mui/icons-material/Dashboard';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import GroupIcon from '@mui/icons-material/Group';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import LogoutIcon from '@mui/icons-material/Logout';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import ReceiptIcon from '@mui/icons-material/Receipt';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import StarsIcon from '@mui/icons-material/Stars';
import StorageIcon from '@mui/icons-material/Storage';
import TrainIcon from '@mui/icons-material/Train';
import { Divider } from '@mui/material';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import withRouter from 'helpers/withRouter';
import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { authSetLanguage, logout, setLoading } from 'store/actions';

const mainListItems = [
  { text: 'Home', icon: <HomeRoundedIcon /> },
  { text: 'Analytics', icon: <AnalyticsRoundedIcon /> },
  { text: 'Clients', icon: <PeopleRoundedIcon /> },
  { text: 'Tasks', icon: <AssignmentRoundedIcon /> },
];

const secondaryListItems = [
  { text: 'Settings', icon: <SettingsRoundedIcon /> },
  { text: 'About', icon: <InfoRoundedIcon /> },
  { text: 'Feedback', icon: <HelpRoundedIcon /> },
];

const MenuContent = ({ user, setLanguage, languages, router, logout, pendingCoachRequests }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const p = location.pathname;
  const [openLanguage, setOpenLanguage] = React.useState(false);

  const handleClickLanguageOpen = () => {
    setOpenLanguage(true);
  };

  const handleLanguageClose = () => {
    setOpenLanguage(false);
  };

  const handleChange = async (event) => {
    await setLanguage(event.target.value);
    setOpenLanguage(false);
  };


  return (
    <Stack sx={{ flexGrow: 1, p: 1, justifyContent: 'space-between' }}>
      <List dense>
        {['admin', 'associate', 'coach', 'finance'].includes(user?.role) && (
          <ListItem disablePadding key="dashboard">
            <ListItemButton component={NavLink} selected={p === '/manager'} to="/manager">
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary={t('Dashboard')} />
            </ListItemButton>
          </ListItem>
        )} 

        {['admin', 'associate'].includes(user?.role) && (
          <ListItem key="inquiries" disablePadding>
            <ListItemButton
              component={NavLink}
              selected={p === '/manager/inquiries'}
              to="/manager/inquiries"
            >
              <ListItemIcon>
                <ArrowRightAltIcon />
              </ListItemIcon>
              <ListItemText primary={t('Inquiries')} />
            </ListItemButton>
          </ListItem>
        )}

        {['coach'].includes(user?.role) && (
            <ListItem key="coach-requests" disablePadding>
                <ListItemButton
                selected={p === '/manager/coach-requests'}
                component={NavLink}
                to="/manager/coach-requests"
                >
                <ListItemIcon>
                    <Badge badgeContent={pendingCoachRequests} color="error">
                    <MoveToInboxIcon />
                    </Badge>
                </ListItemIcon>
                <ListItemText primary={t('Coach requests')} />
                </ListItemButton>
            </ListItem>
        )}

        {['admin', 'associate'].includes(user?.role) && (
            <ListItem key="workshops" disablePadding>
                <ListItemButton
                selected={p === '/manager/workshops'}
                component={NavLink}
                to="/manager/workshops"
                >
                <ListItemIcon>
                    <GroupWorkIcon />
                </ListItemIcon>
                <ListItemText primary={t('Workshops')} />
                </ListItemButton>
            </ListItem>
        )}

        {['finance'].includes(user?.role) && (
            <ListItem key="upcoming" disablePadding>
                <ListItemButton
                    selected={p === '/manager/workshops/upcoming'}
                    component={NavLink}
                    to="/manager/workshops/upcoming"
                >
                <ListItemIcon>
                    <AccessAlarmsIcon />
                </ListItemIcon>
                <ListItemText primary={t('Upcoming Workshops')} />
                </ListItemButton>
            </ListItem>
        )}

        {['finance'].includes(user?.role) && (
            <ListItem key="invoices" disablePadding>
                <ListItemButton
                selected={p === '/manager/invoices'}
                component={NavLink}
                to="/manager/invoices"
                >
                <ListItemIcon>
                    <ReceiptIcon />
                </ListItemIcon>
                <ListItemText primary={t('Invoices')} />
                </ListItemButton>
            </ListItem>
        )}

        {['coach'].includes(user?.role) && (
            <ListItem key="materialOrder" disablePadding>
                <ListItemButton
                    selected={p === '/manager/material-orders'}
                    component={NavLink}
                    to="/manager/material-orders"
                >
                    <ListItemIcon>
                    <ShoppingCartIcon />
                </ListItemIcon>
                <ListItemText primary={t('Material order')} />
                </ListItemButton>
            </ListItem>
        )}

        {['admin', 'associate', 'finance'].includes(user?.role) && (
            <React.Fragment key="coachesList">
                <ListItem disablePadding>
                <ListItemButton
                    selected={p === '/manager/coaches'}
                    component={NavLink}
                    to="/manager/coaches"
                >
                    <ListItemIcon>
                    <GroupIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('Coach')} />
                </ListItemButton>
                </ListItem>
                <Divider />
            </React.Fragment>
        )}

        {['admin', 'associate'].includes(user?.role) && (
            <ListItem key="masterdata" disablePadding>
                <ListItemButton
                    selected={p === '/manager/masterdata'}
                    component={NavLink}
                    to="/manager/masterdata"
                >
                <ListItemIcon>
                    <StorageIcon />
                </ListItemIcon>
                <ListItemText primary={t('Master data')} />
                </ListItemButton>
            </ListItem>
        )}

        {['admin', 'associate'].includes(user?.role) && (
            <ListItem key="news" disablePadding>
                <ListItemButton selected={p === '/manager/news'} component={NavLink} to="/manager/news">
                <ListItemIcon>
                    <SpeakerNotesIcon />
                </ListItemIcon>
                <ListItemText primary={t('News')} />
                </ListItemButton>
            </ListItem>
        )}

        {['admin', 'associate'].includes(user?.role) && (
            <React.Fragment key="evaluations">
                <ListItem disablePadding>
                    <ListItemButton
                        selected={p === '/manager/evaluations/by-coach'}
                        component={NavLink}
                        to="/manager/evaluations/by-coach"
                    >
                        <ListItemIcon>
                            <StarsIcon />
                        </ListItemIcon>
                        <ListItemText primary={t('Evaluations')} />
                    </ListItemButton>
                </ListItem>
                <Divider />
            </React.Fragment>
        )}

        {['coach'].includes(user?.role) && (
            <React.Fragment key="evaluations">
                <ListItem key="evaluations" disablePadding>
                    <ListItemButton
                        selected={p === '/manager/evaluations'}
                        component={NavLink}
                        to="/manager/evaluations"
                    >
                        <ListItemIcon>
                            <StarsIcon />
                        </ListItemIcon>
                        <ListItemText primary={t('Evaluations')} />
                    </ListItemButton>
                </ListItem>
                <Divider />
            </React.Fragment>
        )}

        {['admin', 'associate'].includes(user?.role) && (
            <ListItem disablePadding key="reports">
                <ListItemButton
                selected={p === '/manager/statistics'}
                component={NavLink}
                to="/manager/statistics"
                >
                <ListItemIcon>
                    <BarChartIcon />
                </ListItemIcon>
                <ListItemText primary={t('Reports')} />
                </ListItemButton>
            </ListItem>
        )}

        {/**

        {['admin', 'associate'].includes(user?.role) && (
            <ListItem disablePadding key="export">
                <ListItemButton selected={p === '/manager/export'} component={NavLink} to="/manager/export">
                <ListItemIcon>
                    <ImportExportIcon />
                </ListItemIcon>
                <ListItemText primary={t('Export')} />
                </ListItemButton>
            </ListItem>
        )}

        

        {['admin', 'associate', 'coach'].includes(user?.role) && (
            <ListItem disablePadding key="settings">
                <ListItemButton
                    selected={p === '/manager/settings'}
                    component={NavLink}
                    to="/manager/settings"
                >
                    <ListItemIcon>
                <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary={t('Settings')} />
                </ListItemButton>
            </ListItem>
        )}
             */}

        {['coach'].includes(user?.role) && (
            <ListItem disablePadding key="unavailabilities">
                <ListItemButton
                selected={p === '/manager/unavailabilities'}
                component={NavLink}
                to="/manager/unavailabilities"
                >
                <ListItemIcon>
                    <EventBusyIcon />
                </ListItemIcon>
                <ListItemText primary={t('Unavailabilities')} />
                </ListItemButton>
            </ListItem>
        )}

        {['coach'].includes(user?.role) && (
            <ListItem disablePadding key="expenses">
                <ListItemButton
                selected={p === '/manager/coach/expenses'}
                component={NavLink}
                to="/manager/coach/expenses"
                >
                <ListItemIcon>
                    <TrainIcon />
                </ListItemIcon>
                <ListItemText primary={t('Expenses')} />
                </ListItemButton>
            </ListItem>
        )}

        {['finance'].includes(user?.role) && (
            <ListItem disablePadding key="expenses">
                <ListItemButton
                selected={p === '/manager/finance/expenses'}
                component={NavLink}
                to="/manager/finance/expenses"
                >
                <ListItemIcon>
                    <TrainIcon />
                </ListItemIcon>
                <ListItemText primary={t('Expenses')} />
                </ListItemButton>
            </ListItem>
        )}

        {['finance'].includes(user?.role) && (
            <ListItem disablePadding key="coach-fees">
                <ListItemButton
                selected={p === '/manager/finance/coach-fees'}
                component={NavLink}
                to="/manager/finance/coach-fees"
                >
                <ListItemIcon>
                    <AccountBalanceWalletIcon />
                </ListItemIcon>
                <ListItemText primary={t('Fees')} />
                </ListItemButton>
            </ListItem>
        )}
      </List>

      <List dense>
        <ListItem disablePadding key="lang-select">
            <ListItemButton
                component={NavLink}
                onClick={handleClickLanguageOpen}
            >
                <ListItemIcon>
                    <ReactCountryFlag
                        countryCode={user.language}
                        svg
                        style={{ marginRight: 6 }}
                    />
                </ListItemIcon>
                <ListItemText primary={t('Language')} />
            </ListItemButton>
        </ListItem>

        <ListItem disablePadding key="my-profile">
            <ListItemButton
                component={NavLink}
                to="/manager/profile"
            >
                <ListItemIcon>
                    <AccountCircleIcon />
                </ListItemIcon>
                <ListItemText primary={t('My profile')} />
            </ListItemButton>
        </ListItem>

        <ListItem disablePadding key="logout">
            <ListItemButton
                component={NavLink}
                onClick={logout}
            >
                <ListItemIcon>
                    <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary={t('Logout')} />
            </ListItemButton>
        </ListItem>
    
        
      </List>
      <Dialog open={openLanguage} onClose={handleLanguageClose}>
        <DialogTitle>{t('Select language')}</DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{
              paddingTop: 2,
              paddingBottom: 2,
            }}
          >
            <FormControl variant="standard">
              <InputLabel>{t('Language')}</InputLabel>
              <Select
                value={user?.language}
                onChange={handleChange}
                input={<Input />}
                style={{ width: '100%' }}
              >
                {languages.map((lang) => {
                  return (
                    <MenuItem key={lang} value={lang}>
                      <ReactCountryFlag countryCode={lang} svg style={{ marginRight: 6 }} />
                      {t(`Language ${lang}`)}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLanguageClose} color="primary">
            {t('Cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
}

const mapStateToProps = (state) => ({
    loading: state.ui.loading,
    languages: state.ui.languages,
    pendingCoachRequests: state.ui.pendingCoachRequests,
  });
  
  const mapDispatchToProps = (dispatch) => {
    return {
      setLoading: (value) => {
        dispatch(setLoading(value));
      },
      setLanguage: async (value) => {
        dispatch(authSetLanguage(value));
      },
      logout: () => {
        dispatch(logout());
      },
    };
  };
  
  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MenuContent));
  