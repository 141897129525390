// import InquiryContainer from 'components/inquiry/InquiryContainer';


import Login from 'components/auth/Login';
import PasswordReset from 'components/auth/PasswordReset';
import AuthLayout from 'components/layouts/auth/AuthLayout';
import BackendLayout from 'components/layouts/backend/BackendLayout';
import PublicLayout from 'components/layouts/public/PublicLayout';
import Loader from 'components/ui/AiPageLoader';
import PropTypes from 'prop-types';
import React, { Suspense, useEffect } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import i18n from '../config/i18n';
import { logout } from '../store/actions/auth';
import * as actions from '../store/actions/index';


import AuthRoute from './AuthRoute';
// import BackendRoutes from './BackendRoutes';

const basePath = '';
const defaultLocale = 'de';

const supportedLocale = (locale) => {
  const supportedLocales = ['de', 'en', 'fr', 'it'];
  if(!supportedLocales.includes(locale)) return false;
  return true;
};

const InquiryContainer = React.lazy(() => import('components/inquiry/InquiryContainer'));
const EvaluationAudience = React.lazy(() => import('components/evaluation/AudienceDetail'));

const BackendRoutes = React.lazy(() => import('./BackendRoutes'));

const AiRoutes = ({user, isAuthenticated, logout, title}) => {
  return (
      <Routes>
        <Route element={<PublicLayout />}>          
          <Route path="/" element={<InquiryContainer />} />  
          <Route noTitle path="/evaluations/:hash" element={<EvaluationAudience />} exact />  
        </Route>

        <Route element={<BackendLayout user={user} title={title} logout={logout} />}>          
          <Route path="/manager/*" element={<BackendRoutes />} />
        </Route>
        

        <Route element={<AuthLayout />}>
          <Route path="/login" exact element={<AuthRoute element={Login} />} />
          <Route path="/password-reset/:token" exact element={<AuthRoute element={PasswordReset} />} />
        </Route>
      </Routes>
  );
};
/*
const RootRedirect = () => {
    let [locale] = (
        navigator.language ||
        navigator.browserLanguage ||
        defaultLocale
    ).split('-');
    if (!supportedLocale(locale)) locale = defaultLocale;
    return <Redirect to={`${basePath}/${locale}`} />;
}; */

const LangRedirect = ({ location, lang }) => {
  if (!supportedLocale(lang)) locale = defaultLocale;
  i18n.changeLanguage(lang);

  const newUrl = window?.location.pathname.replace('/' + lang, '');

  return <Navigate to={newUrl.length > 0 ? newUrl : '/' } replace />;
};

const AppRouter = (props) => {
  const { onTryAutoSignup } = props;
  useEffect(() => {
    onTryAutoSignup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BrowserRouter>
      <Suspense fallback={<Loader />}>
      <Routes>
        <Route path="/en" element={<LangRedirect lang="en" />} /> 
        <Route path="/de" element={<LangRedirect lang="de" />} /> 
        <Route path="/fr" element={<LangRedirect lang="fr" />} /> 
        <Route path="/it" element={<LangRedirect lang="it" />} /> 
        <Route index path="/*" element={<AiRoutes {...props} />} />
      </Routes>
      </Suspense>
    </BrowserRouter>
  ); 
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState()),
    logout: () => {
      dispatch(logout());
    },
  };
};

AppRouter.propTypes = {
  onTryAutoSignup: PropTypes.func.isRequired,
  user: PropTypes.object,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.token !== null,
  user: state.auth.user ?? { firstname: '', lastname: '' },
});

export default connect(mapStateToProps, mapDispatchToProps)(AppRouter);
