import { Paper } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { ReactComponent as Logo } from 'assets/img/logo.svg';
import AiSnackbar from 'components/ui/AiSnackbar';
import { Outlet } from 'react-router-dom';

function AuthLayout() {
    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <AiSnackbar />
            <Paper
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: 3,
                }}
            >
                <Box
                    sx={{
                        margin: 1,
                        backgroundColor: (theme) => theme.palette.secondary.main,
                        padding: 3,
                        width: '100%',
                        textAlign: 'center',
                        marginBottom: 5,
                    }}
                    component="span"
                >
                    <Logo style={{ height: '50px', fill: '#000000', color: '#000000' }} />
                </Box>
                <Outlet />
            </Paper>
        </Container>
    );
}


export default AuthLayout;
