import CssBaseline from '@mui/material/CssBaseline';
import Footer from 'components/layouts/public/Footer';
import Header from 'components/layouts/public/Header';
import AiSnackbar from 'components/ui/AiSnackbar';
import { connect } from 'react-redux';
import { Outlet } from 'react-router-dom';

function PublicLayout({ noTitle, children }) {
    return (
        <>
            <CssBaseline />
            <AiSnackbar />
            <Header noTitle={noTitle === true} />
            <Outlet /> 
            <Footer />
        </>
    );
}


export default connect()(PublicLayout);
