import styled from '@emotion/styled';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import { AlertTitle } from '@mui/lab';
import { Box } from '@mui/material';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import AiSnackbar from 'components/ui/AiSnackbar';
import { api } from 'config/config';
import i18n from 'config/i18n';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { authSetUser, setLoading, snackbarError, snackbarSuccess } from 'store/actions';
import AppNavbar from './AppNavBar';
import SideMenu from './SideMenu';

const PREFIX = 'ai-backend-layout';
const classes = {
    appBarSpacer: `${PREFIX}-appBarSpacer`,
};

const RootBox = styled(Box)(({ theme }) => ({
    [`& .${classes.appBarSpacer}`]: theme.mixins.toolbar,
}));

function BackendLayout({
    children,
    user,
    title,
    logout,
    updateUser,
    snackbarError,
    ss,
    loading,
    setLoading,
}) {
    const { t } = useTranslation();

    const requestValidation = async () => {
        try {
            setLoading(true);
            const { data: response } = await axios.request({
                method: 'put',
                url: api.path('coaches.requestValidation'),
            });
            updateUser(response.data);
        } catch (e) {
            snackbarError(i18n.t('Please fill all required fields'));
        }
        setLoading(false);
    };

    const completionAlert = (status) => {
        const incomplete = (
            <Alert
                severity="error"
                action={
                    <Button
                        onClick={requestValidation}
                        startIcon={<AlarmOnIcon />}
                        variant="outlined"
                    >
                        {t('Request validation')}
                    </Button>
                }
            >
                <AlertTitle>{t('Complete profile')} </AlertTitle>
                {t('Complete profile description')}
            </Alert>
        );
        const processing = (
            <Alert severity="info">
                <AlertTitle>Profil wird geprüft</AlertTitle>
                <Typography variant="body2">
                    {t('One moment. Your profile will be checked.')}
                </Typography>
            </Alert>
        );
        switch (status) {
            case 'incomplete':
                return incomplete;
            case 'processing':
                return processing;
            default:
                return '';
        }
    };

    return (
        (
            <RootBox
                component="div"
                sx={{
                    display: 'flex',
                }}
            >
                { user ? (
                    <React.Fragment>
                    <CssBaseline />
                    <AiSnackbar />
                    <SideMenu logout={logout}  user={user} />
                    <AppNavbar logout={logout} user={user} />
                    {/* <Header logout={logout} user={user} title={title || t('App title')} /> */}
                    <Box
                        component="main"
                        sx={{
                            flexGrow: 1,
                            height: '100vh',
                            overflow: 'auto',
                        }}
                    >
                        {completionAlert(user?.state)}
                        
                        <Box component="div" className={classes.appBarSpacer} sx={{ display: { xs: 'block', md: 'none' }}}  />
                        <Outlet />
                    </Box>
                    </React.Fragment>
                ) : (<Outlet />) }
                
            </RootBox>
        )
    );
}

BackendLayout.propTypes = {
    user: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateUser: (state) => {
            dispatch(authSetUser(state));
        },
        snackbarSuccess: (message) => {
            dispatch(snackbarSuccess(message));
        },
        snackbarError: (message) => {
            dispatch(snackbarError(message));
        },
        setLoading: (value) => {
            dispatch(setLoading(value));
        },
    };
};

const mapStateToProps = (state) => ({
    user: state.auth.user,
    loading: state.ui.loading,
});

export default connect(mapStateToProps, mapDispatchToProps)(BackendLayout);
