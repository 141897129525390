import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';

function authRoute({ role, isAuthenticated, element: Component }) {
  const redirectTo = role === 'coach' ? '/manager' : '/manager';
  return (
      !isAuthenticated ? (
          <Component />
      ) : (
        <Navigate to={redirectTo} />
      )
    )
}

authRoute.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.token !== null,
  role: state.auth.role,
});

export default connect(mapStateToProps)(authRoute);
