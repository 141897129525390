import Badge, { badgeClasses } from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';

export default function MenuButton({
  showBadge = false,
  ...props
}) {
  return (
    <Badge
      color="accent"
      variant="dots"
      invisible={!showBadge}
      sx={{
        [`& .${badgeClasses.badge}`]: { right: 2, top: 2 },
        color: '#fff',
      }}
    >
      <IconButton sx={{ color: '#fff'}} size="small" {...props} />
    </Badge>
  );
}
